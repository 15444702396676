"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ActionType = void 0;
var ActionType;
(function (ActionType) {
    ActionType["AddUser"] = "AddUser";
    ActionType["RemoveUser"] = "RemoveUser";
    ActionType["SetToggle"] = "SetToggle";
    ActionType["SetAutoAdjust"] = "SetAutoAdjust";
    ActionType["StartEvent"] = "StartEvent";
    ActionType["EndEvent"] = "EndEvent";
    ActionType["TickSimulation"] = "TickSimulation";
})(ActionType = exports.ActionType || (exports.ActionType = {}));
