"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.sleep = exports.unreachable = void 0;
function unreachable(v) {
    console.warn("unreachable called with", v);
    throw new Error("unreachable");
}
exports.unreachable = unreachable;
function sleep(millis) {
    return new Promise((resolve) => setTimeout(() => resolve(undefined), millis));
}
exports.sleep = sleep;
