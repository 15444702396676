"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MessageType = void 0;
var MessageType;
(function (MessageType) {
    MessageType["LogEntryServer"] = "LogEntryServer";
    MessageType["RemapEntryServer"] = "RemapEntryServer";
    MessageType["RejectEntryServer"] = "RejectEntryServer";
    MessageType["InitialServer"] = "InitialServer";
    MessageType["ReportUndoServer"] = "ReportUndoServer";
    MessageType["SubmitEntryClient"] = "SubmitEntryClient";
    MessageType["RequestUndoClient"] = "RequestUndoClient";
    MessageType["PreferenceDeviceServer"] = "PreferenceDeviceServer";
    MessageType["CreateDeviceClient"] = "CreateDeviceClient";
    MessageType["ReportDeviceClient"] = "ReportDeviceClient";
})(MessageType = exports.MessageType || (exports.MessageType = {}));
